import React from "react"
import withPreview, { getContent } from "../apollo/preview"
import { getSectionLandingPagePreview as PREVIEW_QUERY } from "../preview-queries/sectionLandingPreview"

// Data
import { graphql } from "gatsby"

// Components
import Layout from "../components/Global/Layout"
import Main from "../components/Shared/Main"
import LargeLandingHighlight from "../components/Shared/LargeLandingHighlight"
import Leader from "../components/Shared/Leader"
import LeaderList from "../components/Shared/LeaderList"
import SectionTitle from "../components/Shared/SectionTitle"
import HeroAreaLarge from "../components/Shared/HeroAreaLarge"
import SectionBlurb from "../components/Shared/SectionBlurb"
import LargeTextCTA from "../components/Shared/LargeTextCTA"
import { venueForPage } from "../helpers/venueForPage"
import VenueHireFooterCTA from "../components/Shared/VenueHireFooterCTA"
import Promos from "../components/Shared/Promos"
import SEOSettings from "../components/Shared/SEOSettings"

const TemplateSectionLanding = (props) => {
    const page = getContent(props, "page")
    const venue = venueForPage({
        page: page,
        menus: props.data.menus,
    })

    return (
        <Layout venue={venue} currentLocation={props.path} title={page.title}>
            <SEOSettings entity={page} />
            <HeroAreaLarge title={page.title} introduction={page.page_introduction.introduction} image={page.image.image} />
            <Main>
                <Promos promoItems={page.promoItems} />
                {page.subTitle && <SectionTitle>{page.sub_title?.subTitle}</SectionTitle>}
                {page.large_highlights.sections &&
                    page.large_highlights.sections.map((section, index) => (
                        <div key={`large-highlight-section-${index}`}>
                            {section.title && <SectionTitle>{section.title}</SectionTitle>}
                            {section.description && (
                                <SectionBlurb>
                                    <p>{section.description}</p>
                                </SectionBlurb>
                            )}
                            {section.highlights &&
                                section.highlights.map((highlight, index) => <LargeLandingHighlight key={`large-highlight-${index}`} {...highlight} />)}
                        </div>
                    ))}
                {page.small_highlights.sections &&
                    page.small_highlights.sections.map((section, index) => (
                        <div key={`small-highlight-section-${index}`}>
                            {section.title && <SectionTitle>{section.title}</SectionTitle>}
                            {section.description && (
                                <SectionBlurb>
                                    <p>{section.description}</p>
                                </SectionBlurb>
                            )}
                            {section.highlights && (
                                <LeaderList>
                                    {section.highlights.map((highlight, index) => (
                                        <Leader key={`small-highlight-${index}`} {...highlight} />
                                    ))}
                                </LeaderList>
                            )}
                        </div>
                    ))}

                {page.footer_cta_options && page.footer_cta_options.showPeople && <VenueHireFooterCTA {...page.footer_cta_options} />}
                {page.large_page_cta.largePageCta.title && (
                    <LargeTextCTA>
                        <h2
                            dangerouslySetInnerHTML={{
                                __html: page.large_page_cta.largePageCta.title,
                            }}
                        ></h2>
                        {page.large_page_cta.largePageCta.text && (
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: page.large_page_cta.largePageCta.text,
                                }}
                            ></p>
                        )}
                        {page.large_page_cta.largePageCta.link && (
                            <a href={page.large_page_cta.largePageCta.link.url}>{page.large_page_cta.largePageCta.buttonText}</a>
                        )}
                    </LargeTextCTA>
                )}
            </Main>
        </Layout>
    )
}

export const pageQuery = graphql`
    query SectionLandingPageQuery($databaseId: Int!) {
        menus: allWpMenu {
            results: nodes {
                ...PageMenusNode
            }
        }
        page: wpPage(databaseId: { eq: $databaseId }) {
            ...PageContent
            ...LargeHighlights
            ...SmallHighlights
            ...LargePageCTA
            ...FooterCTASettings
            ...PromoItems
            seo {
                metaDesc
                title
                opengraphTitle
                opengraphDescription
                metaKeywords
                metaRobotsNofollow
                metaRobotsNoindex
                opengraphImage {
                  sourceUrl
                }
            }
        
        }
    }
`

export default withPreview({ preview: PREVIEW_QUERY })(TemplateSectionLanding)
